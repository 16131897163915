//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import check from '../../../assets/images/Sobre/Page5/check.svg';
import outline from '../../../assets/images/Sobre/Page5/outline.svg';
import share from '../../../assets/images/Sobre/Page5/share.svg';

const Item = styled(Paper)(({ theme }) => ({
  margin: '3% 5%',
  border: 'none',
  backgroundColor: 'transparent',
  borderRadius: 0,
  boxShadow: 'none',
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '50vh',
    backgroundColor: '#FFF',
    padding: '0 !important',
    display: 'flex !important',
    '@media (max-width:780px)': {
      marginTop: '50px',
    },
  },
  paper: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    borderRadius: '0 !important',
  },

  center: {
    marginTop: '20px !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    flexDirection: 'column',
  },
  image: {
    width: '25%',
  },
  menuImagens: {
    minWidth: '100vw !important',
    marginTop: '50px',
  },
  title: {
    marginTop: '40px',
    color: '#DD1A1B',
    fontFamily: 'avenir_nextbold',
    fontSize: '40px',
  },
  subtitle: {
    fontFamily: 'avenir_nextregular',
    fontSize: '22px',
    color: '#313133',
    marginTop: '40px',
  },
}));

const Page4: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.Container}>
        <Box sx={{ flexGrow: 1 }}>
          {/* <Grid item md={1} implementation="css" component={''} /> */}
          <Grid container md={11} className={classes.menuImagens}>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <img src={share} className={classes.image} />
                <h5 className={classes.title}>
                  Mobiliade é um
                  <br /> direito de todos
                </h5>
                <p className={classes.subtitle}>
                  Nos antecipamos as mudanças do mundo, pensando em sermos mais
                  práticos e gastarmos nosso tempo com coisas valiosas.
                  Trabalhar onde se mora, perto do seu mercado favorito e dos
                  principais pontos da cidade.
                </p>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <img src={outline} className={classes.image} />
                <h5 className={classes.title}>
                  Compartilhar é<br /> uma virtude
                </h5>
                <p className={classes.subtitle}>
                  Compartilhar contribui para uma cidade e um mundo melhor. Você
                  conhece novos lugares, pessoas e novas possibilidades.
                  Compartilhar não apenas experiências, porque não produtos e
                  serviços?
                </p>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <img src={check} className={classes.image} />
                <h5 className={classes.title}>
                  Serviços que fazem
                  <br /> toda diferença
                </h5>
                <p className={classes.subtitle}>
                  O futuro é agora. Por isso, criamos o conceito de Smart
                  Aparts: um local preparado para te dar a melhor experiência em
                  moradia. Tudo que você sempre sonhou em um único lugar. Não é
                  apenas lindo, é prático!
                </p>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Page4;
