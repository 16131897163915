//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import background from '../../../assets/images/Home/her_img.png';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    minHeight: '100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    padding: '0 !important',
    display: 'flex !important',
    justifyContent: 'center',
  },
  text: {
    alignSelf: 'center',
    color: '#FFF',
    fontFamily: 'avenir_nextbold',
    fontSize: '3rem',
    // paddingTop:'-100px',
    // padding: '10%',
    // textAlign:"center"
  },
}));

const Page5: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container display="flex" className={classes.red} xs={10} md={4}>
        <h1 className={classes.text}>Pensando em você e no seu amanhã.</h1>
      </Grid>
    </Container>
  );
};

export default Page5;
