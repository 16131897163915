//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import ddtotal from '../../../assets/images/Sobre/Page4/ddtotal@2x.png';
import gallura from '../../../assets/images/Sobre/Page4/gallura@2x.png';
import goey from '../../../assets/images/Sobre/Page4/goey@2x.png';
import olbia from '../../../assets/images/Sobre/Page4/olbia@2x.png';
import payout from '../../../assets/images/Sobre/Page4/payout@2x.png';
import xgreen from '../../../assets/images/Sobre/Page4/xgreen@2x.png';

const Item = styled(Paper)(({ theme }) => ({
  margin: '3% 5%',
  border: 'none',
  backgroundColor: 'transparent',
  borderRadius: 0,
  boxShadow: 'none',
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '40vh',
    backgroundColor: '#EBEBEB',
    padding: '0 !important',
    display: 'flex !important',
    '@media (max-width:780px)': {
      marginTop: '50px',
    },
  },
  Container2: {
    minWidth: '100vw !important',
    minHeight: '60vh',
    backgroundColor: '#EBEBEB',
    padding: '0px !important',
    margin: '0px !important',
    display: 'flex !important',
    justifyContent: 'center',

    '@media (max-width:780px)': {
      marginTop: '50px',
    },
  },
  description: {
    height: '40vh',
    paddingTop: '4% !important',
    // justifyContent:"center"
  },

  paper: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    borderRadius: '0 !important',
  },
  title: {
    fontSize: '3rem',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B !important',
    lineHeight: 1,
    '@media (max-width:1380px)': {
      fontSize: '38px',
    },
  },
  subTitle: {
    marginTop: '30px !important',
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#313133',
    '@media (max-width:1380px)': {
      fontSize: '18px',
    },
  },
  image: {
    maxWidth: '50% !important',
    // alignSelf:"center"
    marginTop: '50px',
  },
  menuImagens: {
    minWidth: '100vw !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
  center: {
    marginTop: '20px',
    display: 'flex !important',
    justifyContent: 'center !important',
  },
}));

const Page4: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.Container}>
        {/* Text */}
        <Grid container item xs={12} md={12} lg={12}>
          <Grid item md={4} implementation="css" component={''} />
          <Grid item xs={12} md={4} className={classes.description}>
            <Item className={classes.title}>
              Juntos podemos ir muito mais longe.
            </Item>
            <Item className={classes.subTitle}>
              Criamos e empreendemos em alguns negócios e startups , sempre com
              o maior objetivo de melhorar a vida das pessoas ao nosso redor.
            </Item>
          </Grid>
        </Grid>

        {/* Images */}
      </Container>
      <Container className={classes.Container2}>
        <Box sx={{ flexGrow: 1 }}>
          {/* <Grid item md={1} implementation="css" component={''} /> */}
          <Grid container md={11} className={classes.menuImagens}>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <a
                  href="https://olbialife.com.br/"
                  target="_blank"
                  className={classes.center}
                >
                  <img src={olbia} className={classes.image} />
                </a>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <a
                  href="http://www.goeyja.com.br"
                  target="_blank"
                  className={classes.center}
                >
                  <img src={goey} className={classes.image} />
                </a>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <a
                  href="http://www.ddtotal.com.br"
                  target="_blank"
                  className={classes.center}
                >
                  <img src={ddtotal} className={classes.image} />
                </a>
              </Item>
            </Grid>

            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <a
                  href="http://www.gallura.com.br"
                  target="_blank"
                  className={classes.center}
                >
                  <img src={gallura} className={classes.image} />
                </a>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <a
                  href="http://www.payout.com.br"
                  target="_blank"
                  className={classes.center}
                >
                  <img src={payout} className={classes.image} />
                </a>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item className={classes.center}>
                <a
                  href="http://www.xgreen.app"
                  target="_blank"
                  className={classes.center}
                >
                  <img src={xgreen} className={classes.image} />
                </a>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Page4;

{
  /* <Grid item md={4} implementation="css" component={''} /> */
}
