//@ts-nocheck
import React from "react";

const ProgressBar = (props:any) => {
  const { text, completed } = props;
  

  const containerStyles = {
    height: '2px',
    width: '100%',
    backgroundColor: "#707070",
    
    
  }

  const fillerStyles = {
    height: '10px',
    marginTop:'-4px',
    width: `${completed == "0" ? 3 : completed}%`,
    backgroundColor: '#FF0000',
    transition: 'width 1s ease-in-out',
    borderRadius: 'inherit',
    textAlign: 'right',
  }

  const labelStyles = {
    padding: 5,
    color: '#313133',
    fontWeight: 'bold',
    marginBottom:'10px',
    fontSize:'18px',
    fontFamily:'avenir_nextbold',
    marginTop:'40px'
  }

  return (
    <>
    <span style={labelStyles}>{`${completed}% ${text}`}</span>
    <div style={containerStyles}>
      <div style={fillerStyles}>
        
      </div>
    </div>
    </>
    
  );
};

export default ProgressBar;