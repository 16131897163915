import React from 'react';
import { Link } from 'react-router-dom';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from '../Sidebar/SidebarElements';
import './styles.css';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink onClick={toggle}>
            <Link to="/sobre">Sobre Nós</Link>
          </SidebarLink>
          {/* <SidebarLink onClick={toggle}>
            <Link to="/home">Conceito</Link>
          </SidebarLink> */}
          <SidebarLink onClick={toggle}>
            <Link to="/empreendimento">Empreendimentos</Link>
          </SidebarLink>
          <SidebarLink onClick={toggle}>
            <Link to="/processo">Como fazemos</Link>
          </SidebarLink>
          <SidebarLink onClick={toggle}>
            <Link to="/contato">Contato</Link>
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
