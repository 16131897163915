//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import background from '../../../assets/images/Home/background.png';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '1920px !important',
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    minHeight: '100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0 !important',
    display: 'flex !important',
  },
  BoxSuperior: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  BoxSuperior2: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '@media (max-width:780px)': {
      maxWidth: '20px !important',
    },
  },
  BoxSuperior3: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    '@media (max-width:780px)': {
      display: 'none !important',
    },
  },
  Box: {
    alignSelf: 'flex-end',
    height: '33vh',
    backgroundColor: '#FFF',
    minWidth: '100%',
    justifyContent: 'center',
    '@media (max-width:780px)': {
      height: '40vh',
    },
  },
  red: {
    alignSelf: 'flex-end',
    height: '33vh',
    minWidth: '100%',
    backgroundColor: '#DD1A1B',
    '@media (max-width:780px)': {
      height: '40vh',
      maxWidth: '20px !important',
    },
  },
  firstText: {
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    justifyContent: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    padding: '20px',
    lineHeight: 1,
  },
  secondText: {
    fontFamily: 'avenir_nextregular',
    justifyContent: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    // padding: '20px',
    fontSize: '1.37rem',
    color: '#505050',
    lineHeight: 1,
  },
  span: {
    color: '#505050',
    fontWeight: 'bold',
    fontFamily: 'avenir_nextbold',
  },
}));

const Page1: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container xs={12} md={5} className={classes.BoxSuperior}>
        <Grid container display="flex" className={classes.Box}>
          <h1 className={classes.firstText}>
            Moradias que transformam e trazem qualidade de vida para as pessoas.
          </h1>
        </Grid>
      </Grid>
      <Grid container xs={12} md={4} className={classes.BoxSuperior3}>
        <Grid container display="flex" className={classes.Box}>
          <h3 className={classes.secondText}>
            <span className={classes.span}>Wyncorp, </span>nosso foco está em
            você. Experiência, criatividade e atenção em cada detalhe. Este é o
            combustível para nossas construções inovadoras.
          </h3>
        </Grid>
      </Grid>
      <Grid container xs={12} md={1} className={classes.BoxSuperior2}>
        <Grid container display="flex" className={classes.red}></Grid>
      </Grid>
    </Container>
  );
};

export default Page1;
