//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import background from '../../../assets/images/Olbia/Page5/terreo@2x.png';
import Grid from '@mui/material/Grid';
import img1 from '../../../assets/images/wyncorp_ImgObras/ImgConceicao/IMG_4717.jpg';
import img2 from '../../../assets/images/wyncorp_ImgObras/ImgConceicao/IMG_4719.jpg';
import img3 from '../../../assets/images/wyncorp_ImgObras/ImgConceicao/IMG_4726.jpg';
import img4 from '../../../assets/images/wyncorp_ImgObras/ImgConceicao/IMG_4733.jpg';
import img5 from '../../../assets/images/wyncorp_ImgObras/ImgConceicao/IMG_4735.jpg';
import img6 from '../../../assets/images/wyncorp_ImgObras/ImgConceicao/IMG_4738.jpg';
import ProgressBar from './ProgressBar';

import FsLightbox from "fslightbox-react";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    minHeight: '100vh !important',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0 !important',
    display: 'flex !important',
  },
  block: {
    paddingTop:'10%',
    display:'flex',
    flexDirection:'column !important',
    '@media (max-width:780px)': {
      padding: '15px',
    },
  },
  title:{
    fontSize: '42px',
    // lineHeight:'64px',
    letterSpacing:'-1.05px',
    textAlign:'left',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    '@media (max-width:780px)': {
      
    },
  },
  address:{
    fontSize: '28px',
    // lineHeight:'64px',
    letterSpacing:'-0.7px',
    textAlign:'left',
    color: '#313133',
    fontFamily: 'avenir_nextbold',
    '@media (max-width:780px)': {
      
    },
  },
  init:{
    fontSize: '24px',
    // lineHeight:'64px',
    letterSpacing:'-0.6px',
    textAlign:'left',
    color: '#313133',
    fontFamily: 'avenir_regular',
    '@media (max-width:780px)': {
      
    },
  },
  images:{
    marginTop:'16%',
    marginLeft:'10%',
    marginBottom:'50px',
    display:'flex',
    flexDirection:'row',
    minHeight:'440px',
    gap:'20px',
    '@media (max-width:780px)': {
      marginLeft:'5% !important',
    },
    
  },
  thumb:{
    height:'240px',
    width:'256px',
    cursor:'pointer',
    '@media (max-width:780px)': {
      height:'250px !important',
      width:'95% !important',
     
    },
  }
}));

const Page8: React.FC = () => {
  const classes = useStyles();
  const [toggler, setToggler] = React.useState(false);
  const [show, setShow] = React.useState(0);
  return (
    <Container className={classes.Container}>
      <Grid container xs={12} md={12} style={{backgroundColor:"#FFF"}}>
        
        <Grid item md={1} implementation="css" component={''} />
        <Grid container xs={12} md={3} className={classes.block}>
            <p className={classes.title}>Olbia Smart Aparts</p>
            <p className={classes.address}>Conceição, Campinas/SP</p>
            

            <ProgressBar text={"Fundação"} completed={'0'} />
            
            <ProgressBar text={"Estrutura"} completed={'0'} />
            <ProgressBar text={"Alvenaria"} completed={'0'} />
            <ProgressBar text={"Acabamento Externo"} completed={'0'} />
            <ProgressBar text={"Acabamento Interno"} completed={'0'} />

        </Grid>
        <Grid container xs={12} md={6} className={classes.images}>
          <img src={img1} className={classes.thumb} onClick={ () => {setShow(0);setToggler(!toggler)}} />
          <img src={img2} className={classes.thumb} onClick={ () => {setShow(1);setToggler(!toggler)}} />
          <img src={img3} className={classes.thumb} onClick={ () => {setShow(2);setToggler(!toggler)}} />
          <img src={img4} className={classes.thumb} onClick={ () => {setShow(3);setToggler(!toggler)}} />
          <img src={img5} className={classes.thumb} onClick={ () => {setShow(4);setToggler(!toggler)}} />
          <img src={img6} className={classes.thumb} onClick={ () => {setShow(5);setToggler(!toggler)}} />

          {/* <Grid container xs={12} md={4} >
            
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid> */}
         
          <FsLightbox
				    toggler={toggler}
            sourceIndex={show}
            sources={[
             img1,img2,img3,img4,img5,img6
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page8;

