//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import './styles.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import andar1 from '../../../assets/images/Olbia/Carousel/planta/olbiaRiachuelo_P_1Andar.png';
import andar10 from '../../../assets/new_img/olbiaRiachuelo_P_10Andar.png';
import Apartamento from '../../../assets/images/Olbia/Carousel/planta/olbiaRiachuelo_P_Apartamento.png';
import Pavimento from '../../../assets/new_img/olbiaRiachuelo_P_Pavimento.png';
import Rooftop from '../../../assets/new_img/olbiaRiachuelo_P_Rooftop.png';

const imagens = [
  { nome: '1º Andar', image: andar1 },
  { nome: '10º Andar', image: andar10 },
  { nome: 'Apartamento', image: Apartamento },
  { nome: 'Pavimento', image: Pavimento },
  { nome: 'Rooftop', image: Rooftop },
];

// Imagens Riachuello

// Imagens Planta

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: '0px !important',
    padding: '0px !important',
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#154456',
    display: 'flex',
    flexDirection: 'row !important',
    padding: '0px !important',
    margin: '0px !important',
  },
  firstBlock: {
    paddingTop: '15%',
    padding: '20px',
  },
  title: {
    fontSize: '62px',
    fontFamily: 'avenir_nextbold',
    color: '#FEFEFE',
    lineHeight: '1',
    textAlign: 'left',
    '@media (max-width:768px)': {
      fontSize: '48px',
    },
  },
  text: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#FEFEFE',
    marginTop: '30px',
    textAlign: 'left',
    '@media (max-width:768px)': {
      fontSize: '18px',
    },
    '@media (max-width:1380px)': {
      fontSize: '18px',
    },
  },
}));

const Page4: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      {/* <Grid item md={4} implementation="css" component={''} /> */}
      <Grid item container xs={12} md={12}>
        <Grid item container xs={12} md={4} className={classes.firstBlock}>
          <span className={classes.title}>Compactos & Inteligentes</span>
          <p className={classes.text}>
            Apartamentos compactos e ambientes que se encaixam perfeitamente. Os
            apartamentos contam com 1 dormitório, 1 banheiro, e uma cozinha,
            distribuídos de forma dinâmica. Tenha acesso a serviços como limpeza
            de quarto, co-working, coffee shop , academia e muito mais.
          </p>
        </Grid>
        <Grid item md={1} implementation="css" component={''} />
        <Grid item xs={12} md={7} className={classes.firstBlock}>
          <Carousel
            width={'100%'}
            dynamicHeight={false}
            showThumbs={false}
            className={classes.carousel}
            ariaLabel={false}
            showIndicators={false}
            showArrows={true}
            infiniteLoop={true}
            transitionTime={300}
            useKeyboardArrows
            autoPlay={true}
            emulateTouch={true}
          >
            {imagens.map((value, index) => {
              return (
                <div key={index}>
                  <img src={value.image} />
                  <p className="legend">{value.nome}</p>
                </div>
              );
            })}

            {/* <div>
              <img src={Apartamento} />
              <p className="legend">PLANTA BAIXA TÉRREO 2</p>
            </div> */}
          </Carousel>
        </Grid>
      </Grid>
      {/* <Grid item md={4} implementation="css" component={''} /> */}
    </Container>
  );
};

export default Page4;
