//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#FFF',
    padding: '0 !important',
    display: 'flex !important',
    '@media (max-width:780px)': {
      minHeight: 'auto',
    },
  },
  imagem: {
    width: '100%',
    alignSelf: 'center',
    '@media (max-width:780px)': {
      display: 'none',
    },
  },
  firstText: {
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    justifyContent: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    fontSize: '2.6rem',
    paddingTop: '5%',
    '@media (max-width:780px)': {
      textAlign: 'left',
      marginLeft: '10px',
      marginRight: '10px',
      fontSize: '38px',
    },

    '@media (min-height:1000px)': {
      paddingTop: '10%',
    },
  },
  secondText: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#313133',
    paddingTop: '50px',
    '@media (max-width:780px)': {
      paddingLeft: '15px',
      paddingTop: '40px',
      width: '90vw',
    },
  },

  align: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '55px',
    paddingTop: '30vh',
    '@media (max-width:780px)': {
      paddingTop: '15vh',
      // justifyContent: 'flex-start',
      marginLeft: '5px',
      // padding: '100px 15px 0 15px',
    },
  },
}));

const Page1: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container className={classes.align}>
        <Grid item xs={12} md={5}>
          <h5 className={classes.firstText}>
            O sucesso é resultado de
            <br /> atitudes com propósitos e<br /> transformadoras.
          </h5>
          <p className={classes.secondText}>
            Objetivos claros garantem que a inovação se torne uma realidade.
            Cuidamos de todos os detalhes ao longo do caminho. Dedicados a um
            resultado final impressionante. Saiba mais como fazemos.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page1;
