//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import background from '../../../assets/images/Processo/Page5/imag_work@2x.png';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    minHeight: '70vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0px !important',
    display: 'flex !important',
    justifyContent: 'center',
    margin: '0px !important',
  },
  red: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    alignSelf: 'center',
    color: '#FFF',
    fontFamily: 'avenir_nextbold',
    fontSize: '3rem',
    padding: '10%',
    textAlign: 'center',
    '@media (max-width:780px)': {
      fontSize: '38px',
    },
  },
}));

const Page5: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container display="flex" className={classes.red} xs={12} md={8}>
        <h1 className={classes.text}>
          Focados no trabalho com o cliente, valorizando a arquitetura
          funcional.
        </h1>
      </Grid>
    </Container>
  );
};

export default Page5;
