import React, { useState } from 'react';
import Page7 from '../../components/Olbia/Page7';
import Footer from '../../components/Footer';
import Navbar from '../../components/NavbarBlack';
import Sidebar from '../../components/Sidebar';

const Galeria = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Page7 />     
      <Footer />
    </>
  );
};

export default Galeria;
