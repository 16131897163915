//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import background from '../../../assets/images/Olbia/Page5/terreo@2x.png';
import Grid from '@mui/material/Grid';
import img1 from '../../../assets/images/wyncorp_ImgObras/update/1bed8009-50c2-4a70-a851-1f152ac3a627.JPG';
import img2 from '../../../assets/images/wyncorp_ImgObras/update/7ffde09c-178d-4ca4-99db-f95e0f97fab1.JPG';
import img3 from '../../../assets/images/wyncorp_ImgObras/update/8c025187-31c6-47b2-9918-4ef249c3cbb9.JPG';
import img4 from '../../../assets/images/wyncorp_ImgObras/update/10815c14-ed42-4ce2-94df-aa43dfd55950.JPG';
import img5 from '../../../assets/images/wyncorp_ImgObras/update/48058234-a8b7-428b-a4bc-608228eea675.JPG';
import img6 from '../../../assets/images/wyncorp_ImgObras/update/ca1606ae-a3ac-4040-a8dc-6aeb5e142fbf.JPG';
import ProgressBar from './ProgressBar';
import FsLightbox from "fslightbox-react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    minHeight: '100vh !important',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0 !important',
    display: 'flex !important',
  },
  block: {
    paddingTop:'10%',
    display:'flex',
    flexDirection:'column !important',
    '@media (max-width:780px)': {
      padding: '15px',
    },
  },
  title:{
    fontSize: '42px',
    // lineHeight:'64px',
    letterSpacing:'-1.05px',
    textAlign:'left',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    '@media (max-width:780px)': {
      
    },
  },
  address:{
    fontSize: '28px',
    // lineHeight:'64px',
    letterSpacing:'-0.7px',
    textAlign:'left',
    color: '#313133',
    fontFamily: 'avenir_nextbold',
    '@media (max-width:780px)': {
      
    },
  },
  init:{
    fontSize: '24px',
    // lineHeight:'64px',
    letterSpacing:'-0.6px',
    textAlign:'left',
    color: '#313133',
    fontFamily: 'avenir_regular',
    '@media (max-width:780px)': {
      
    },
  },
  images:{
    marginTop:'16%',
    marginLeft:'10%',
    marginBottom:'50px',
    display:'flex',
    flexDirection:'row',
    gap:'20px',
    minHeight:'440px',
    '@media (max-width:780px)': {
      marginLeft:'5% !important',
      
    },
    
  },
  thumb:{
    height:'240px',
    width:'256px',
    cursor:'pointer',
    '@media (max-width:780px)': {
      height:'250px !important',
      width:'95% !important',
    },
  }
}));

const Page7: React.FC = () => {
  const classes = useStyles();
  const [toggler, setToggler] = React.useState(false);
  const [show, setShow] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  

  return (
    <Container className={classes.Container}>
      <Grid container xs={12} md={12} style={{backgroundColor:"#FFF"}}>
        
        <Grid item md={1} implementation="css" component={''} />
        <Grid container xs={12} md={3} className={classes.block}>
            <p className={classes.title}>Olbia Smart Aparts</p>
            <p className={classes.address}>Riachuelo, Campinas/SP</p>
            <p className={classes.init}>Obras Iniciadas</p>

            <ProgressBar text={"Fundação"} completed={'60'} />
            
            <ProgressBar text={"Estrutura"} completed={'10'} />
            <ProgressBar text={"Alvenaria"} completed={'0'} />
            <ProgressBar text={"Acabamento Externo"} completed={'0'} />
            <ProgressBar text={"Acabamento Interno"} completed={'0'} />

        </Grid>
        <Grid container xs={12} md={6} className={classes.images}>
          
          <img src={img1} className={classes.thumb} onClick={ () => {setShow(0);setToggler(!toggler)}} alt=""/>
          <img src={img2} className={classes.thumb} onClick={ () => {setShow(1);setToggler(!toggler)}} alt=""/>
          <img src={img3} className={classes.thumb} onClick={ () => {setShow(2);setToggler(!toggler)}} alt=""/>
          <img src={img4} className={classes.thumb} onClick={ () => {setShow(3);setToggler(!toggler)}} alt=""/>
          <img src={img5} className={classes.thumb} onClick={ () => {setShow(4);setToggler(!toggler)}} alt=""/>
          <img src={img6} className={classes.thumb} onClick={ () => {setShow(5);setToggler(!toggler)}} alt=""/>

          {/* <Grid container xs={12} md={4} >
             
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid>
          <Grid container xs={12} md={4} >
          <img src={img1} style={{height:'200px'}} />
          </Grid> */}
         
          <FsLightbox
				    toggler={toggler}
            sourceIndex={show}
            sources={[
             img1,img2,img3,img4,img5,img6
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page7;

