import React, { useState } from 'react';
import Page1 from '../../components/Olbia/Page1';
import Page2 from '../../components/Olbia/Page2';
import Page3 from '../../components/Olbia/Page3';
import Page4 from '../../components/Olbia/Page4';
import Page5 from '../../components/Olbia/Page5';
import Page6 from '../../components/Olbia/Page6';

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

const Empreendimento: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      <Page6 />
      <Footer />
    </>
  );
};

export default Empreendimento;
