//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
// import background from '../../../assets/images/Page2/background.png';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '1920px !important',
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0 !important',
    display: 'flex !important',
    backgroundColor: '#EBEBEB',
  },
  BoxSuperior: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    height: '75vh',
    alignSelf: 'flex-end',
    margin: '0px !important',
    '@media (max-width:780px)': {
      height: '100vh',
    },
  },
  smallBlock: {
    backgroundColor: '#DD1A1B',
    height: '30%',
    alignSelf: 'flex-end',
    '@media (max-width:780px)': {
      display: 'none !important',
    },
  },
  secondBlock: {
    height: '100%',
    paddingTop: '10% !important',
    marginRight: '15px !important',
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    fontFamily: 'avenir_nextbold',
  },
  secondText: {
    fontSize: '2rem',
    color: '#DD1A1B',
    fontFamily: 'avenir_nextbold',
    textAlign: 'left',
  },
  thirdText: {
    color: '#313133',
    fontFamily: 'avenir_nextregular',
    fontSize: '1.37rem',
    paddingTop: '30px',
  },
}));

const Page2: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      {/* <Box className={classes.Container}> */}
      <Grid
        container
        xs={12}
        md={10}
        className={classes.BoxSuperior}
        spacing={2}
      >
        <Grid item md={1} implementation="css" component={''} />
        <Grid item xs={2} className={classes.smallBlock}></Grid>
        <Grid item xs={12} md={8} className={classes.secondBlock}>
          <p className={classes.title}>Nosso conceito</p>
          <h2 className={classes.secondText}>
            A Wyncorp se baseia no conceito de smart apart e colivings, para
            entregar a melhor experiência em moradia e investimento para você.
          </h2>
          <h5 className={classes.thirdText}>
            Na Wyncorp, oferecemos apartamentos que contam com uma enorme gama
            de serviços integrados. Nossos colivings contam com coffee shops,
            espaços de co-working, cozinha gourmet compartilhada, área de lazer
            com diversas opções de diversão, academias e muito mais. Conheça
            nossos produtos e surpreenda-se.
          </h5>
        </Grid>
      </Grid>
      {/* </Box> */}
    </Container>
  );
};

export default Page2;

{
  /* <Grid container xs={10} className={classes.BoxSuperior} spacing={2}>
  <Grid item md={1} implementation="css" smDown component={''} />
  <Grid item xs={2} className={classes.smallBlock}></Grid>
  <Grid item sm={12} xs={6} lg={8} className={classes.secondBlock}>
    <p className={classes.title}>Nosso conceito</p>
    <h2 className={classes.secondText}>
      A Wyncorp se baseia no conceito de smart apart e colivings, para
      entregar a melhor experiência em moradia e investimento para você.
    </h2>
    <h5 className={classes.thirdText}>
      Na Wyncorp, oferecemos apartamentos que contam com uma enorme gama
      de serviços integrados. Nossos colivings contam com coffee shops,
      espaços de co-working, cozinha gourmet compartilhada, área de lazer
      com diversas opções de diversão, academias e muito mais. Conheça
      nossos produtos e surpreenda-se.
    </h5>
  </Grid>
</Grid> */
}
