//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#EBEBEB',
    padding: '0 !important',
    display: 'flex !important',
    flexDirection: 'column',
    '@media (max-width:780px)': {
      minHeight: 'auto',
    },
  },
  align: {
    display: 'flex',
    justifyContent: 'flex-start',
    // marginLeft: '55px',

    paddingTop: '15vh !important',
    '@media (max-width:780px)': {
      paddingTop: '15vh',
      // justifyContent: 'flex-start',
      // marginLeft: '5px',
      // padding: '100px 15px 0 15px',
    },
  },
  number: {
    fontSize: '64px',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    width: '114px !important',
    height: '114px',
    backgroundColor: '#D9D9D9',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width:780px)': {
      fontSize: '48px',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  secondText: {
    paddingTop: '50px',
    fontSize: '62px',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    lineHeight: '1',
    '@media (max-width:780px)': {
      fontSize: '48px',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  thirdText: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#313133',
    paddingTop: '30px',
    '@media (max-width:780px)': {
      textAlign: 'left',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  padding30: {
    marginBottom: '80px !important',
  },
}));

const Page3: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container className={classes.align}>
        <Grid item md={1} implementation="css" component={''} />
        <Grid item xs={12} md={4}>
          <div className={classes.number}>3</div>
          <div className={classes.secondText}>Planejamento</div>
          <div className={classes.thirdText}>
            Criamos um plano de trabalho com cronogramas. Porque temos
            experiencia em entregar resultados surpreendentes. Vamos garantir
            que tudo seja "business as usual" para você.
          </div>
        </Grid>
        <Grid item md={1} implementation="css" component={''} />
      </Grid>
      <Grid container className={classes.align}>
        <Grid item md={6} implementation="css" component={''} />
        <Grid item xs={12} md={4} className={classes.padding30}>
          <div className={classes.number}>4</div>
          <div className={classes.secondText}>
            Início do
            <br /> projeto
          </div>
          <div className={classes.thirdText}>
            Atendemos a todos os documentos, autorizações, normas e licenças
            necessárias para que, a realização do empreendimento prossiga da
            melhor forma possível.
          </div>
        </Grid>
        <Grid item md={1} implementation="css" component={''} />
      </Grid>
    </Container>
  );
};

export default Page3;
