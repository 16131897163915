//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0 !important',
    display: 'flex !important',
    backgroundColor: '#EBEBEB',
    '@media (max-width:780px)': {
      minHeight: '50vh',
      marginTop: '0px !important',
    },
  },
  BoxSuperior: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    height: '75vh',
    alignSelf: 'flex-end',
    margin: '0px !important',
    '@media (max-width:780px)': {
      height: '50vh',
    },
  },
  smallBlock: {
    backgroundColor: '#DD1A1B',
    height: '30%',
    alignSelf: 'flex-end',
    '@media (max-width:780px)': {
      display: 'none',
    },
  },
  secondBlock: {
    height: '100%',
    paddingTop: '10% !important',
    marginRight: '15px !important',
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    fontFamily: 'avenir_nextbold',
  },
  secondText: {
    fontSize: '2rem',
    color: '#DD1A1B',
    fontFamily: 'avenir_nextbold',
    textAlign: 'left',
  },
  thirdText: {
    color: '#313133',
    fontFamily: 'avenir_nextregular',
    fontSize: '1.37rem',
    paddingTop: '30px',
  },
}));

const Page6: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      {/* <Box className={classes.Container}> */}
      <Grid
        container
        xs={12}
        md={10}
        className={classes.BoxSuperior}
        spacing={2}
      >
        <Grid item md={1} implementation="css" component={''} />
        <Grid item xs={2} className={classes.smallBlock}></Grid>
        <Grid item xs={12} md={8} className={classes.secondBlock}>
          <p className={classes.title}>Responsabilidade</p>
          <h2 className={classes.secondText}>
            Pensando em você e no seu amanhã.
          </h2>
          <h5 className={classes.thirdText}>
            A moradia hoje está sendo consumida como um serviço. Por isso,
            criamos o conceito de integração. O prédio oferecendo todos os
            serviços do cotidiano em um aplicativos no seu celular - Mixed use.
          </h5>
        </Grid>
      </Grid>
      {/* </Box> */}
    </Container>
  );
};

export default Page6;
