//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './styles.css';

import Academia from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Academia.png';
import Apartamento from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Apartamento.png';
import BikeEletrica from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_BikeEletrica.png';
import CarroEletrico from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_CarroEletrico.png';
import Conveniencia from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Conveniencia.png';
import Coworking from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Coworking.png';
import Elevadores from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Elevadores.png';
import ExternaConveniencia from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_ExternaConveniencia.png';
import GourmetKitchen from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_GourmetKitchen.png';
import HomeCinema from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_HomeCinema.png';
import Lavanderia from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Lavanderia.png';
import Lobby from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Lobby.png';
import Recepcao from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Recepcao.png';
import Rooftop from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_Rooftop.png';
import Spa from '../../../assets/images/Olbia/Carousel/wyncorp/wyncorp_spa.png';

const imagens = [
  { nome: 'Academia', image: Academia },
  { nome: 'Apartamento', image: Apartamento },
  { nome: 'Bike Eletrica', image: BikeEletrica },
  { nome: 'Carro Eletrico', image: CarroEletrico },
  { nome: 'Convivência', image: Conveniencia },
  { nome: 'Coworking', image: Coworking },
  { nome: 'Hall', image: Elevadores },
  { nome: 'Convivência Externa', image: ExternaConveniencia },
  { nome: 'Cozinha Gourmet', image: GourmetKitchen },
  { nome: 'Home Cinema', image: HomeCinema },
  { nome: 'Lavanderia', image: Lavanderia },
  { nome: 'Lobby', image: Lobby },
  { nome: 'Recepção', image: Recepcao },
  { nome: 'Rooftop', image: Rooftop },
  { nome: 'Spa', image: Spa },
];

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: '0px !important',
    padding: '0px !important',
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#EBEBEB',
    // paddingTop: '10% !important',
    display: 'flex !important',
    flexDirection: 'column',
    margin: '0px !important',
    padding: '0px !important',
    '@media (max-width:780px)': {
      flexDirection: 'column',
    },
    '@media (max-width:1920px)': {
      minHeight: '80vh',
    },
  },
  text: {
    marginTop: '10%',
    marginLeft: '10%',
    color: '#313133',
    fontFamily: 'avenir_nextbold',
    fontSize: '40px',
  },
  carousel: {
    flexDirection: 'row',
    // minHeight:"67.6vh"
  },
  blockRight: {
    backgroundColor: '#DD1A1B',
    display: 'flex',
    justifyContent: 'center',
    // padding:'2%'
  },
  fonteGigante: {
    fontSize: '82px',
    color: '#FFF',
    fontFamily: 'avenir_nextbold',
    // justifyContent:'center',
    alignSelf: 'center',
    '@media (max-width:1380px)': {
      fontSize: '62px',
    },
  },
  blockCarousel: {
    // backgroundColor:"blue"
  },
  legend: {
    backgroundColor: 'transparent',
    color: '#fefefe !important',
    fontWeight: 'bold',
    paddingTop: '-100px',
    opacity: '1',
    fontSize: '18px',
    alignSelf: 'flex-start',
    display: 'flex',
    fontFamily: 'avenir_nextregular',
    zIndex: '-1',
    position: 'absolute',
  },
  button: {
    width: '30%',
    backgroundColor: '#000',
    //   marginRight:"2%",
    height: '50px',
    border: 'none',
    fontSize: '24px',
    color: '#FFF',
  },
  button2: {
    width: '30%',
    marginRight: '5%',
    height: '50px',
    color: '#FFF',
    backgroundColor: '#000',
    border: 'none',
    fontSize: '24px',
  },
}));

const Page3: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container item xs={12} md={12}>
        <p className={classes.text}>
          Tudo pensado,
          <br />
          feito para você.
        </p>
      </Grid>
      <Grid container item xs={12} md={12} className={classes.carousel}>
        <Grid item xs={12} md={8} className={classes.blockCarousel}>
          <Carousel
            width={'100%'}
            dynamicHeight={false}
            showThumbs={false}
            className={classes.carousel}
            ariaLabel={false}
            showIndicators={false}
            showArrows={true}
            infiniteLoop={true}
            transitionTime={300}
            useKeyboardArrows
            autoPlay={true}
            emulateTouch={true}
          >
            {imagens.map((value, index) => {
              return (
                <div key={index}>
                  <img src={value.image} />
                  <p className="legend">{value.nome}</p>
                </div>
              );
            })}
          </Carousel>
        </Grid>
        <Grid item xs={12} md={4} className={classes.blockRight}>
          <p className={classes.fonteGigante}>
            Small
            <br /> space
            <br /> Big
            <br /> life!
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page3;
