//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import background from '../../../assets/images/Home/background.png';
import Grid from '@mui/material/Grid';
import './styles.css';
import { Link } from 'react-router-dom';
import assistente from '../../assets/images/Footer/Group.png';
import facebook from "../../assets/medias/facebook.png";
import instagram from "../../assets/medias/insta.png";
import linkedin from "../../assets/medias/linkedin.png";
import twitter from "../../assets/medias/XMLID_826_.png";
import { Icon, Wrapper, WrapperIcon } from './styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    minHeight: '30vh !important',
    backgroundColor: '#313131',
    display: 'flex',
  },
  list: {
    paddingTop: '50px',
    // justifyContent: "center"
  },
  title: {
    fontSize: '0.95rem',
    color: '#FEFEFE',
    fontFamily: 'avenir_nextbold',
    marginTop: '50px !important',
    ['@media (max-width:780px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '0px !important',
    },
  },
  group: {
    alignSelf: 'flex-start',
  },
  combo: {
    marginRight: '10px',
    marginTop: '60% !important',
    ['@media (max-width:780px)']: {
      marginTop: '0px !important',
      alignContent: 'center',
    },
  },
  assistente: {
    height:'95%',
    display:'flex',
    justifyContent:'center',
    flexDirection:'row',
    alignSelf:'flex-end',
    ['@media (max-width:780px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none !important',
    },
  },
  row: {
    ['@media (max-width:780px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'flex',
      flexDirection: 'row',
    },
  },
  sub: {
    ['@media (max-width:780px)']: {
      // eslint-disable-line no-useless-computed-key
      alignSelf: 'center',
      marginLeft: '40px',
    },
  },
  clearMobile: {
    ['@media (max-width:820px)']: {
      display: 'none !important',
    },
  },

}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container xs={12} md={12} sm={12}>
        <Grid container xs={12} md={4} sm={12}>
          <img src={assistente} className={classes.assistente} />
        </Grid>
        {/* <Grid container xs={12} md={1} className={classes.list}>
        </Grid> */}
        <Grid container xs={12} sm={12} md={4} className={classes.list}>
          <Wrapper style={{ width: '100%' }}>
            <p style={{ fontFamily: 'avenir_nextbold', color: '#C2B59B', lineHeight: '30px', letterSpacing: '-0.55px', fontSize: '28px' }}>Um novo conceito de moradia.</p>
            <p style={{ fontFamily: 'avenir_nextregular', color: '#FFF', lineHeight: '48px', letterSpacing: '-0.95px', fontSize: '38px' }} >Simplicidade é o mais alto<br /> padrão de sofisticação.</p>
            {/* <hr style={{ border: '0.5px solid #FFFFFF', width: '100%', opacity: '0.5',marginTop:'193px' }} className={classes.clearMobile}/> */}
          </Wrapper>

        </Grid>

        <Grid container xs={12} md={4} sm={12} className={classes.list}>
          <Wrapper style={{ width: '100%' }}>
            <h1>REDES SOCIAIS</h1>
            <hr style={{ border: '0.5px solid #FFFFFF', width: '100%', opacity: '0.5', textAlign: 'left' }} />

            <WrapperIcon>

              <Icon>
                <a href="https://www.linkedin.com/company/wyncorp-incorporadora" target="_blank">
                  <img src={linkedin} style={{ width: '22.5px' }} />
                  <p>LinkedIn</p>
                </a>
              </Icon>

              <Icon>
                <a href="https://www.facebook.com/Wyncorp-103615625567620" target="_blank">
                  <img src={facebook} style={{ width: '11.37px' }} />
                  <p>Facebook</p>
                </a>
              </Icon>

              <Icon>
                <a href="https://www.instagram.com/wyncorpinc/" target="_blank">
                  <img src={instagram} style={{ width: '22.78px' }} />
                  <p>Instagram</p>
                </a>
              </Icon>

              <Icon>
                <a href="" target="_blank">
                  <img src={twitter} style={{ width: '29.5px' }} />
                  <p>Twitter</p>
                </a>
              </Icon>
            </WrapperIcon>

          </Wrapper>

          <Wrapper style={{ width: '100%' }}>
            <h1>LOCALIZAÇÃO</h1>
            <hr style={{ border: '0.5px solid #FFFFFF', width: '100%', opacity: '0.5', textAlign: 'left' }} />
            <p> <span style={{ fontFamily: 'avenir_nextbold', color: '#FFF', lineHeight: '30px', letterSpacing: '-0.55px' }}>Campinas/SP</span><br />
            <span style={{ fontFamily: 'avenir_nextregular', fontSize: '16px', letterSpacing: '0.4px', lineHeight: '22px', color: "#FFF" }}>Rua Lotario Novaes, 71<br />Zip 13076-150</span></p>
            {/* <hr style={{ border: '0.5px solid #FFFFFF', width: '100%', opacity: '0.5', textAlign: 'left',marginTop:'40px' }} /> */}
            <span style={{ fontFamily: 'avenir_nextregular', fontSize: '11px', letterSpacing: '0.4px', lineHeight: '22px', color: "#FFF",textAlign:'right',margin:'auto' }}>Copyright © 2022 Wyncorp. Todos os direitos reservados.</span>
          </Wrapper>
        </Grid>
      </Grid>
      {/* <Grid container xs={12} md={12} sm={12}>
        <Grid container xs={12} md={4} className={classes.list}></Grid>
        <Grid container xs={12} sm={12} md={8} className={classes.list} style={{marginTop:'-100px'}}>
            <Wrapper style={{ width: '100%' }}>
              <hr style={{ border: '0.5px solid #FFFFFF', width: '100%', opacity: '0.5', textAlign: 'left' }} />
            </Wrapper>

          </Grid>
      </Grid> */}
    </Container>
  );
};

export default Footer;
