//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import olbia1 from '../../../assets/images/Olbia/olbia_Logo.png';
import olbia2 from '../../../assets/images/Olbia/olbia_Riachuello.png';

import CoworkingImg from '../../../assets/images/Conceicao/Carousel/olbiaConceicao_Coworking.png';
import GourmetImg from '../../../assets/images/Conceicao/Carousel/olbiaConceicao_EspacoGourmet.png';

import './styles.css';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: '0px !important',
    padding: '0px !important',
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#EBEBEB',
    // paddingTop: '10% !important',
    display: 'flex !important',
    flexDirection: 'column',
    margin: '0px !important',
    padding: '0px !important',
    '@media (max-width:780px)': {
      flexDirection: 'column',
    },
    '@media (max-width:1920px)': {
      minHeight: '80vh',
    },
  },
  text: {
    marginTop: '10%',
    marginLeft: '10%',
    color: '#313133',
    fontFamily: 'avenir_nextbold',
    fontSize: '40px',
  },
  carousel: {
    flexDirection: 'row',
    // minHeight:"67.6vh"
  },
  blockRight: {
    backgroundColor: '#DD1A1B',
    display: 'flex',
    justifyContent: 'center',
    // padding:'2%'
  },
  fonteGigante: {
    fontSize: '82px',
    color: '#FFF',
    fontFamily: 'avenir_nextbold',
    // justifyContent:'center',
    alignSelf: 'center',
    '@media (max-width:1380px)': {
      fontSize: '62px',
    },
  },
  blockCarousel: {
    // backgroundColor:"blue"
  },
  legend: {
    backgroundColor: 'transparent',
    color: '#fefefe !important',
    fontWeight: 'bold',
    paddingTop: '-100px',
    opacity: '1',
    fontSize: '18px',
    alignSelf: 'flex-start',
    display: 'flex',
    fontFamily: 'avenir_nextregular',
    zIndex: '-1',
    position: 'absolute',
  },
  button: {
    width: '30%',
    backgroundColor: '#000',
    //   marginRight:"2%",
    height: '50px',
    border: 'none',
    fontSize: '24px',
    color: '#FFF',
  },
  button2: {
    width: '30%',
    marginRight: '5%',
    height: '50px',
    color: '#FFF',
    backgroundColor: '#000',
    border: 'none',
    fontSize: '24px',
  },
}));

const Page3: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container item xs={12} md={12}>
        <p className={classes.text}>
          Estilo, charme &
          <br />
          praticidade.
        </p>
      </Grid>
      <Grid container item xs={12} md={12} className={classes.carousel}>
        <Grid item xs={12} md={8} className={classes.blockCarousel}>
          <Carousel
            width={'100%'}
            dynamicHeight={false}
            showThumbs={false}
            className={classes.carousel}
            ariaLabel={false}
            showIndicators={false}
            showArrows={true}
            infiniteLoop={true}
            transitionTime={300}
            useKeyboardArrows
            autoPlay={true}
            emulateTouch={true}
          >
            <div>
              <img src={CoworkingImg} />
              <p className="legend">Coworking</p>
            </div>
            <div>
              <img src={GourmetImg} />
              <p className="legend">Espaço Gourmet</p>
            </div>
          </Carousel>
        </Grid>
        <Grid item xs={12} md={4} className={classes.blockRight}>
          <p className={classes.fonteGigante}>
            Viva o
            <br /> presente
            <br /> com mais
            <br /> futuro
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page3;
