//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import './styles.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import planta from '../../../assets/images/Conceicao/Page4/pic1@2x.png';
import LavanderiaImg from '../../../assets/images/Conceicao/Carousel/olbiaConceicao_Lavanderia.png';
import LobbyImg from '../../../assets/images/Conceicao/Carousel/olbiaConceicao_Lobby.png';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: '0px !important',
    padding: '0px !important',
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#C2B59B',
    display: 'flex',
    flexDirection: 'row !important',
    padding: '0px !important',
    margin: '0px !important',
  },
  firstBlock: {
    paddingTop: '15%',
    padding: '20px',
  },
  title: {
    fontSize: '62px',
    fontFamily: 'avenir_nextbold',
    color: '#FEFEFE',
    lineHeight: '1',
    textAlign: 'left',
    '@media (max-width:768px)': {
      fontSize: '48px',
    },
  },
  text: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#FEFEFE',
    marginTop: '30px',
    textAlign: 'left',
    '@media (max-width:768px)': {
      fontSize: '18px',
    },
    '@media (max-width:1380px)': {
      fontSize: '18px',
    },
  },
  p10: {
    padding: '10px',
  },
}));

const Page4: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      {/* <Grid item md={4} implementation="css" component={''} /> */}
      <Grid item container xs={12} md={12} className={classes.p10}>
        <Grid item container xs={12} md={4} className={classes.firstBlock}>
          <span className={classes.title}>
            Simplicidade é o<br />
            mais alto padrão
            <br /> de sofisticação.
          </span>
          <p className={classes.text}>
            Áreas equipadas e decoradas com muito bom gosto e elegância. Design
            funcional para o seu dia a dia. Todos entregues decorados e
            equipados com eletrodomésticos, de acordo com o memorial descritivo
            do empreendimento.
          </p>
        </Grid>
        <Grid item md={1} implementation="css" component={''} />
        <Grid item xs={12} md={7} className={classes.firstBlock}>
          <Carousel
            width={'100%'}
            dynamicHeight={false}
            showThumbs={false}
            className={classes.carousel}
            ariaLabel={false}
            showIndicators={false}
            showArrows={true}
            infiniteLoop={true}
            transitionTime={300}
            useKeyboardArrows
            autoPlay={true}
            emulateTouch={true}
          >
            <div>
              <img src={LavanderiaImg} />
              <p className="legend">Lavanderia</p>
            </div>
            <div>
              <img src={LobbyImg} />
              <p className="legend">Lobby</p>
            </div>
          </Carousel>
        </Grid>
      </Grid>
      {/* <Grid item md={4} implementation="css" component={''} /> */}
    </Container>
  );
};

export default Page4;
