function phoneMask(value) {
  if (value == '' || value == undefined) return value;
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(\d{5})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{5})\d+?$/, '$1');
}

export { phoneMask };
