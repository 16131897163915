//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import olbia from '../../../assets/images/Olbia/olbia_Logo@2x.png';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    display: 'flex !important',
    flexDirection: 'row',
    backgroundColor: '#C2B59B',
  },
  firstText: {
    paddingTop: '10px',
    fontSize: '3rem',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
  },
  firstBlock: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '80%',
  },
}));

const Page1: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid item md={4} implementation="css" component={''} />
      <Grid item container xs={12} md={4} className={classes.firstBlock}>
        <img src={olbia} className={classes.image} />
      </Grid>
      <Grid item md={4} implementation="css" component={''} />
    </Container>
  );
};

export default Page1;
