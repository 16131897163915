//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import olbia from '../../../assets/images/Empreendimento/Page2/olbia_Conceicao@2x.png';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#F2F2F2',
    paddingTop: '10% !important',
    display: 'flex !important',
    flexDirection: 'row',
    '@media (max-width:780px)': {
      flexDirection: 'column',
    },
  },
  align: {
    display: 'flex',
    justifyContent: 'flex-start',
    // marginLeft: '55px',

    paddingTop: '15vh !important',
    '@media (max-width:780px)': {
      paddingTop: '15vh',
      // justifyContent: 'flex-start',
      // marginLeft: '5px',
      // padding: '100px 15px 0 15px',
    },
  },
  firstText: {
    paddingTop: '10px',
    fontSize: '3rem',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
  },
  secondText: {
    paddingTop: '10px',
    fontSize: '2.5rem',
    fontFamily: 'avenir_nextbold',
    color: '#313133',
    lineHeight: '1',
    '@media (max-width:780px)': {
      fontSize: '38px',
      //   marginLeft: '10px',
      //   marginRight: '10px',
    },
  },
  thirdText: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#313133',
    paddingTop: '40px',
    textAlign: 'left',
    '@media (max-width:780px)': {
      textAlign: 'left',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },

  image: {
    width: '80%',
    // height:"60vh"
    '@media (max-width:780px)': {
      width: '100%',
    },
  },
  firstBlock: {
    padding: '40px 0px',
  },
  info: {
    // marginLeft: '20%',
    paddingTop: '14px',
    color: '#FFF',
    fontFamily: 'avenir_nextregular',
    fontSize: '14px',
    textAlign: 'left',
  },
  price: {
    //   marginLeft: '20%',
    fontFamily: 'avenir_nextbold',
    fontSize: '3.87rem',
    '@media (max-width:780px)': {
      fontSize: '38px',
    },
    '@media (max-width:1380px)': {
      fontSize: '48px',
    },
    textAlign: 'left',
    color: '#FEFEFE',
  },
  priceInfo: {
    fontFamily: 'avenir_nextregular',
    fontSize: '22px',
    textAlign: 'left',
    color: '#FEFEFE',
    marginTop: '20px',
    lineHeight: '0',
  },
  parcela: {
    marginTop: '20px',
    fontFamily: 'avenir_nextbold',
    fontSize: '40px',
    textAlign: 'left',
    color: '#C2B59B',
    lineHeight: '0',
    '@media (max-width:780px)': {
      fontSize: '30px',
      marginLeft: '10px',
    },
  },
  secondBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    height: '25%',
    backgroundColor: '#C2B59B',
    width: '80%',
    paddingLeft: '15%',
    '@media (max-width:780px)': {
      width: '100%',
      paddingLeft: '5%',
      height: '20vh',
    },
  },
  local: {
    fontSize: '14px',
    fontFamily: 'avenir_nextbold',
    textAlign: 'left',
    color: '#313133',
    marginTop: '10px',
  },
  button: {
    marginTop: '20px',
    height: '50px',
    width: '180px',
    padding: '15px 25px 15px 25px',
    backgroundColor: '#DD1A1B',
    color: '#FFF',
    cursor: 'pointer',
  },
  btn: {
    color: '#FFF !important',
    fontFamily: 'avenir_nextbold',
    '&:hover': {
      color: '#FFF !important',
    },
  },
  buttonB: {
    marginTop: '20px',
    height: '50px',
    width: '195px',
    padding: '15px 25px 15px 25px',
    backgroundColor: '#313133',
    color: '#FFF',
    cursor: 'pointer',
    textAlign:'center'
  },
}));

const Page2: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid item md={1} implementation="css" component={''} />
      <Grid item xs={12} md={4} className={classes.firstBlock}>
        <div className={classes.firstText}>Olbia Smart Apart</div>
        <div className={classes.secondText}>Conceição, Cambuí, Campinas/SP</div>
        <div className={classes.thirdText}>
          Olbia Smart Apart Conceição é um empreendimento imobiliário num dos
          bairros mais tradicionais e sofisticados de Campinas/SP. Próximo de
          tudo o que você precisa para uma vida mais prática.
        </div>
        <div style={{display:'flex',flexDirection:'row',gap:'20px'}}>
          <div className={classes.button} style={{ textAlign: 'center' }}>
            {' '}
            <Link to="/conceicao" className={classes.btn}>
              SAIBA MAIS
            </Link>
          </div>

          <div className={classes.buttonB}>
              <Link to="/galeria-con" className={classes.btn}>
              VER FOTOS
              </Link>
            </div>

        </div>
      </Grid>
      <Grid item md={1} implementation="css" component={''} />
      <Grid item xs={12} md={5}>
        <div className={classes.firstText}>
          <img src={olbia} className={classes.image} />
        </div>
        <div className={classes.secondBlock}>
          <h5 className={classes.info}>Próximo lançamento.</h5>
          <h5 className={classes.price}>Saiba mais.</h5>
        </div>
        <h5 className={classes.local}>Residencial / Campinas/SP</h5>
      </Grid>
    </Container>
  );
};

export default Page2;
