//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import olbia from '../../../assets/images/Empreendimento/Page1/olbia_Riachuello.png';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#EBEBEB',
    paddingTop: '10% !important',
    display: 'flex !important',
    flexDirection: 'row',
    '@media (max-width:780px)': {
      flexDirection: 'column',
    },
  },
  align: {
    display: 'flex',
    justifyContent: 'flex-start',
    // marginLeft: '55px',

    paddingTop: '15vh !important',
    '@media (max-width:780px)': {
      paddingTop: '15vh',
      // justifyContent: 'flex-start',
      // marginLeft: '5px',
      // padding: '100px 15px 0 15px',
    },
  },
  firstText: {
    paddingTop: '10px',
    fontSize: '3rem',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
  },
  firstText3: {
    paddingTop: '10px',
    fontSize: '3rem',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    '@media (max-width:780px)': {
      lineHeight: 1,
      paddingBottom: '10px',
      // justifyContent: 'flex-start',
      // marginLeft: '5px',
      // padding: '100px 15px 0 15px',
    },
  },
  secondText: {
    paddingTop: '10px',
    fontSize: '2.5rem',
    fontFamily: 'avenir_nextbold',
    color: '#313133',
    lineHeight: '1',
    '@media (max-width:780px)': {
      fontSize: '38px',
      //   marginLeft: '10px',
      //   marginRight: '10px',
    },
  },
  thirdText: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#313133',
    paddingTop: '40px',
    textAlign: 'left',
    '@media (max-width:780px)': {
      textAlign: 'left',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },

  image: {
    width: '80%',
    // height:"60vh"
    '@media (max-width:780px)': {
      width: '100%',
    },
  },
  firstBlock: {
    padding: '40px 0px',
  },
  info: {
    // marginLeft: '20%',
    paddingTop: '35px',
    color: '#FFF',
    fontFamily: 'avenir_nextregular',
    fontSize: '14px',
    textAlign: 'left',
  },
  price: {
    //   marginLeft: '20%',
    fontFamily: 'avenir_nextbold',
    fontSize: '22px',
    textAlign: 'left',
    color: '#FEFEFE',
    //   padding: '0px',
  },
  priceInfo: {
    // marginLeft: '20%',
    fontFamily: 'avenir_nextregular',
    fontSize: '22px',
    textAlign: 'left',
    color: '#FEFEFE',
    marginTop: '20px',
    lineHeight: '0',
  },
  parcela: {
    marginTop: '20px',
    fontFamily: 'avenir_nextbold',
    fontSize: '22px',
    textAlign: 'left',
    color: '#C2B59B',
    lineHeight: '0',
    '@media (max-width:780px)': {
      fontSize: '18px',
      // marginLeft: '10px',
    },
    '@media (max-width:1400px)': {
      // fontSize: '30px',
      // marginLeft: '5px',
    },
    //   padding: '0px',
  },
  secondBlock: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '25%',
    backgroundColor: '#154456',
    width: '80%',
    paddingLeft: '10%',
    '@media (max-width:780px)': {
      width: '100%',
      paddingLeft: '2%',
      height: '20vh',
    },
    '@media (max-width:1400px)': {
      paddingLeft: '10%',
    },
  },
  local: {
    fontSize: '14px',
    fontFamily: 'avenir_nextbold',
    textAlign: 'left',
    color: '#313133',
    marginTop: '10px',
  },
  button: {
    marginTop: '20px',
    height: '50px',
    width: '195px',
    padding: '15px 25px 15px 25px',
    backgroundColor: '#DD1A1B',
    color: '#FFF',
    cursor: 'pointer',
  },
  buttonB: {
    marginTop: '20px',
    height: '50px',
    width: '195px',
    padding: '15px 25px 15px 25px',
    backgroundColor: '#313133',
    color: '#FFF',
    cursor: 'pointer',
    textAlign:'center'
  },
  btn: {
    color: '#FFF !important',
    fontFamily: 'avenir_nextbold',
    '&:hover': {
      color: '#FFF !important',
    },
    '@media (max-width:830px)': {
      fontSize: '13.5px !important',
    }
  },
  real: {
    fontSize: '18px',
  },
  tnc: {
    fontSize: '22px',
    color: '#FFF',
    fontFamily: 'avenir_nextregular',
  },
  text: {
    fontFamily: 'avenir_nextregular',
    fontSize: '14px',
    color: '#FEFEFE',
    lineHeight: 1,
    display: 'block',
    '@media (max-width:830px)': {
      fontSize: '10px !important',
    },
  },
  text2: {
    fontFamily: 'avenir_nextregular',
    fontSize: '12px',
    color: '#FEFEFE',
    lineHeight: 1,
    display: 'block',
    '@media (max-width:830px)': {
      marginTop: '10px',
      fontSize: '8px',
    },
  },
  text3: {
    fontFamily: 'avenir_nextregular',
    fontSize: '10px',
    color: '#FEFEFE',
    lineHeight: 2,
    display: 'block',
    '@media (max-width:830px)': {
      fontSize: '8px',
    },
  },
  diaria: {
    fontSize: '40px',
    '@media (max-width:830px)': {
      fontSize: '28px',
    },
  },
  sublocal: {
    fontSize: '14px',
    fontFamily: 'avenir_nextregular',
    textAlign: 'left',
    color: '#313133',
    marginTop: '10px',
    marginBottom:'20px'
  },
  obra:{
    height:'49px',backgroundColor:'red',width:'80%',textAlign:'center',paddingTop:'10px',
    '@media (max-width:830px)': {
      width: '100%',
    },
  }
}));

const Page1: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid item md={1} implementation="css" component={''} />
      <Grid item xs={12} md={4} className={classes.firstBlock}>
        <div className={classes.firstText3}>Olbia Smart Apart</div>
        <div className={classes.secondText}>Riachuelo, Campinas/SP</div>
        <div className={classes.thirdText}>
          Apartamentos compactos e ambientes que se encaixam perfeitamente. Os
          apartamentos contam com 1 dormitório, 1 banheiro e uma cozinha,
          distribuídos de forma dinâmica. Tenha acesso a serviços como limpeza
          do apartamento, bike elétrica, co-working, coffee shop e muito mais.
        </div>
        <div style={{display:'flex',flexDirection:'row',gap:'20px'}}>
          <div className={classes.button}>
            <Link to="/olbia" className={classes.btn}>
              QUERO INVESTIR
            </Link>
          </div>

          <div className={classes.buttonB}>
            <Link to="/galeria-ri" className={classes.btn}>
            VER FOTOS
            </Link>
          </div>
        </div>
        
        
      </Grid>
      <Grid item md={1} implementation="css" component={''} />
      <Grid item xs={12} md={5}>
        <div className={classes.firstText}>
          <img src={olbia} className={classes.image} />
        </div>
        <div className={classes.obra}>
          <p style={{fontSize:'22px',color:'#FFF'}}>OBRA INICIADA</p>
        </div>
        <div className={classes.secondBlock}>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <span className={classes.text}>INVESTIMENTO À PARTIR DE</span>
              <span className={classes.parcela}>R$ 239.999,00</span>
            </Grid>
            <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
              <span className={classes.text2}>INVESTIMENTO À PARTIR DE:</span>
              <span
                className={classes.parcela}
                style={{ marginTop: '30px', lineHeight: '1.4' }}
              >
                R$ <span className={classes.diaria}>38,90</span>/dia
              </span>
              <span className={classes.text3}>
                (R$ 1.067,00 MENSAIS) + ENTRADAS &<br /> PARCELAS INTERMEDIÁRIAS
              </span>
            </Grid>
          </Grid>
        </div>
        <h5 className={classes.local}>Residencial / Campinas/SP <span className={classes.sublocal}>- Rua Riachuelo, 515</span></h5>
      </Grid>
    </Container>
  );
};

export default Page1;
/*

Com RS 26, 90 por dia vc compra seu apto.Sofisticado e acessivel!
*/
