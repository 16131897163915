//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import background from '../../../assets/images/Olbia/Page5/terreo@2x.png';
import Grid from '@mui/material/Grid';
import bike from '../../../assets/images/Olbia/Page5/Icon/bike@2x.png';
import map from '../../../assets/images/Olbia/Page5/Icon/map@2x.png';
import iosfit from '../../../assets/images/Olbia/Page5/Icon/ios-fitness@2x.png';
import user from '../../../assets/images/Olbia/Page5/Icon/users-line@2x.png';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    height: '100vh !important',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0 !important',
    display: 'flex !important',
  },
  BoxSuperior: {
    // display: 'flex',
    // justifyContent: 'flex-start',
    // flexDirection: 'row',
  },

  red: {
    alignSelf: 'flex-end',
    height: '25vh',
    minWidth: '100%',
    backgroundColor: '#DD1A1B',
    '@media (max-width:780px)': {
      display: 'none !important',
    },
  },
  background: {
    backgroundColor: '#F2F2F2',
    marginTop: '20vh !important',
    marginRight: '25vh',
    '@media (max-width:780px)': {
      marginTop: '0px !important',
      marginRight: '0px',
    },
  },
  block: {
    display: 'block !important',
    '@media (max-width:780px)': {
      padding: '15px',
    },
  },
  title: {
    marginTop: '100px',
    fontSize: '18px',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
  },
  subtitle: {
    fontFamily: 'avenir_nextbold',
    fontSize: '40px',
    color: '#313133',
    textAlign: 'left',
    marginTop: '24px',
    '@media (max-width:1380px)': {
      fontSize: '32px',
    },
    '@media (max-width:780px)': {
      fontSize: '22px',
    },
  },
  comboIcon: {
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'space-between',
    marginTop: '50px',
    '@media (max-width:1380px)': {
      marginTop: '15px',
    },
    '@media (max-width:780px)': {
      // padding: '15px'
    },
  },
  icon: {
    height: '48px',
    width: '48px',
  },
  text: {
    marginTop: '60px',
    fontSize: '22px',
    color: '#313133',
    fontFamily: 'avenir_nextregular',
    '@media (max-width:1380px)': {
      fontSize: '18px',
      marginTop: '35px',
    },
  },
  textSpan: {
    color: '#DD1A1B',
  },
}));

const Page5: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container xs={12} md={12} className={classes.background}>
        <Grid container xs={12} md={2} className={classes.BoxSuperior}>
          <Grid container display="flex" className={classes.red}></Grid>
        </Grid>
        <Grid item md={1} implementation="css" component={''} />
        <Grid container xs={12} md={7} className={classes.block}>
          <p className={classes.title}>Smart Cities</p>
          <p className={classes.subtitle}>
            Tudo que você precisa, bem pertinho de você. Qualidade de vida, mais
            mobilidade e muito mais tempo.
          </p>
          <Grid container xs={12} md={8} className={classes.comboIcon}>
            <Grid container xs={3} md={3} className={classes.comboIcon}>
              <img src={map} className={classes.icon} />
            </Grid>
            <Grid container xs={3} md={3} className={classes.comboIcon}>
              <img src={user} className={classes.icon} />
            </Grid>
            <Grid container xs={3} md={3} className={classes.comboIcon}>
              <img src={iosfit} className={classes.icon} />
            </Grid>
            <Grid container xs={3} md={3} className={classes.comboIcon}>
              <img src={bike} className={classes.icon} />
            </Grid>
          </Grid>
          <p className={classes.text}>
            Na Wyncorp, oferecemos apartamentos que contam com uma enorme gama
            de serviços integrados. Nossos colivings contam com{' '}
            <span className={classes.textSpan}>
              coffee shops, espaços de co-working, cozinha gourmet
              compartilhada, área de lazer com diversas opções de diversão,
              academias
            </span>{' '}
            e muito mais. Conheça nossos produtos e surpreenda-se.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page5;
