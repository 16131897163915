import React, { useState } from 'react';
import Page1 from '../../components/Processo/Page1';
import Page2 from '../../components/Processo/Page2';
import Page3 from '../../components/Processo/Page3';
import Page4 from '../../components/Processo/Page4';
import Page5 from '../../components/Processo/Page5';
import Footer from '../../components/Footer';
import Navbar from '../../components/NavbarBlack';
import Sidebar from '../../components/Sidebar';

const Processo: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />

      <Footer />
    </>
  );
};

export default Processo;
