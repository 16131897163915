//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import olbia from '../../../assets/images/Olbia/olbia_Riachuelo@2x.png';
import remax from '../../../assets/images/Olbia/remax_logo@2x.png';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#FFF',
    // paddingTop: '10% !important',
    display: 'flex !important',
    flexDirection: 'row',
    '@media (max-width:780px)': {
      flexDirection: 'column',
    },
  },
  align: {
    display: 'flex',
    justifyContent: 'flex-start',
    // marginLeft: '55px',

    // paddingTop: '15vh !important',
    '@media (max-width:780px)': {
      paddingTop: '15vh',
      // justifyContent: 'flex-start',
      // marginLeft: '5px',
      // padding: '100px 15px 0 15px',
    },
  },
  firstText: {
    paddingTop: '10%',
    fontSize: '3rem',
    fontFamily: 'avenir_nextbold',
    color: '#313133',
  },
  secondText: {
    paddingTop: '10px',
    fontSize: '40px',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    lineHeight: '1',
  },
  thirdText: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#313133',
    paddingTop: '40px',
    textAlign: 'left',
    '@media (max-width:780px)': {
      textAlign: 'left',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover !important',
    '@media (max-width:780px)': {
      width: '100%',
    },
  },
  firstBlock: {
    padding: '40px 0px',
  },
  smallImage: {
    marginTop: '100px',
    width: '250px',
    height: 'auto',
  },
  description:{
    fontSize:'22px',
    fontFamily:"avenir_nextbold",
    color:'#313133',
    marginTop:'30px',
  }
}));

const Page2: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid item md={1} implementation="css" component={''} />
      <Grid item xs={12} md={4} className={classes.firstBlock}>
        <div className={classes.firstText}>Seu apartamento muito +smart</div>
        <div className={classes.secondText}>
          Um condomínio, dezenas de serviços, conheça o Olbia Smart Apart
        </div>
        <div className={classes.thirdText}>
          Apartamentos compactos e ambientes que se encaixam perfeitamente. Os
          apartamentos contam com 1 dormitório, 1 banheiro e uma cozinha,
          distribuídos de forma dinâmica. Tenha acesso a serviços como limpeza
          de quarto, co-working, coffee shop , academia e muito mais.
        </div>
        <p className={classes.description}>Apartamentos inteligentes <br/>de 11,30m2 a 16,84m2 </p>
        <div>
          <img src={remax} className={classes.smallImage} />
        </div>
      </Grid>
      <Grid item md={1} implementation="css" component={''} />
      <Grid item xs={12} md={5}>
        <img src={olbia} className={classes.image} />
      </Grid>
    </Container>
  );
};

export default Page2;
