//@ts-nocheck
import React from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import apartment from '../../../assets/images/Sobre/Page1/Apartment-draw2@2x.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: '#FFF',
    padding: '0 !important',
    display: 'flex !important',
  },
  imagem: {
    width: '100%',
    alignSelf: 'center',
    '@media (max-width:780px)': {
      display: 'none',
    },
  },
  firstText: {
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
    justifyContent: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    paddingTop: '5%',
    '@media (max-width:780px)': {
      paddingLeft: '15px',
      width: '100vw',
    },

    '@media (min-height:1000px)': {
      paddingTop: '10%',
    },
  },
  secondText: {
    fontSize: '22px',
    fontFamily: 'avenir_nextregular',
    color: '#313133',
    '@media (max-width:780px)': {
      paddingLeft: '15px',
      paddingTop: '40px',
      width: '90vw',
      backgroundImage: `url(${apartment})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  },

  align: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '30vh',
    '@media (max-width:780px)': {
      paddingTop: '5vh',
      justifyContent: 'flex-start',
      // padding: '100px 15px 0 15px',
    },
  },
}));

const Page1: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.Container}>
      <Grid container className={classes.align}>
        <Grid item xs={12} md={4}>
          <img src={apartment} className={classes.imagem} />
        </Grid>
        <Grid item xs={12} md={6}>
          <h5 className={classes.firstText}>
            Um novo conceito de moradia,
            <br /> mais inteligente.
          </h5>
          <p className={classes.secondText}>
            Nascemos com a proposta de trazer um novo conceito de moradia, com
            espaços inteligentes e serviços integrados, oferecendo o que há de
            mais moderno com o melhor custo benefício. Para isso buscamos ser a
            melhor empresa de incorporação inteligente do mercado. Temos como
            principais valores, o respeito, a ética, a sustentabilidade, o
            comprometimento e a empatia. Prezamos pela excelência e valores
            morais para oferecer as melhores experiências.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page1;
