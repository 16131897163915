//@ts-nocheck
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { makeStyles, Theme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import balloon from '../../../assets/images/Olbia/Page6/balloon.svg';
import atendente from '../../../assets/images/Olbia/Page6/atendente@2x.png';
import { useForm } from 'react-hook-form';
import './styles.css';
import { phoneMask } from '../../../services/mask';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  Container: {
    minWidth: '100vw',
    // zIndex: '999',
    minHeight: '100vh !important',
    backgroundColor: '#FFF',
    padding: '0 !important',
    display: 'flex !important',
  },
  BoxSuperior: {
    // display: 'flex',
    // justifyContent: 'flex-start',
    // flexDirection: 'row',
  },

  red: {
    alignSelf: 'flex-end',
    height: '25vh',
    minWidth: '100%',
    backgroundColor: '#DD1A1B',
    '@media (max-width:780px)': {
      display: 'none !important',
    },
  },
  background: {
    backgroundColor: '#F2F2F2',
    marginTop: '20vh !important',
    marginRight: '25vh',
    '@media (max-width:780px)': {
      marginTop: '0px !important',
      marginRight: '0px',
    },
  },
  block: {
    display: 'block !important',
    '@media (max-width:780px)': {
      padding: '15px',
    },
  },
  title: {
    marginTop: '100px',
    fontSize: '18px',
    fontFamily: 'avenir_nextbold',
    color: '#DD1A1B',
  },
  subtitle: {
    fontFamily: 'avenir_nextbold',
    fontSize: '40px',
    color: '#313133',
    textAlign: 'left',
    marginTop: '24px',
    '@media (max-width:1380px)': {
      fontSize: '32px',
    },
    '@media (max-width:780px)': {
      fontSize: '22px',
    },
  },
  comboIcon: {
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'space-between',
    marginTop: '50px',
    '@media (max-width:1380px)': {
      marginTop: '15px',
    },
    '@media (max-width:780px)': {
      // padding: '15px'
    },
  },
  icon: {
    height: '48px',
    width: '48px',
  },
  text: {
    marginTop: '60px',
    fontSize: '22px',
    color: '#313133',
    fontFamily: 'avenir_nextregular',
    '@media (max-width:1380px)': {
      fontSize: '18px',
      marginTop: '35px',
    },
  },
  textSpan: {
    color: '#DD1A1B',
  },
  containerFluid: {
    paddingTop: '10%',
    display: 'flex',
    // justifyContent:"center",
  },
  title: {
    fontSize: '40px',
    fontFamily: 'avenir_nextbold',
    textAlign: 'left',
    color: '#313133',
    '@media (max-width:780px)': {
      fontSize: '28px !important',
    },
    '@media (max-width:1380px)': {
      fontSize: '28px !important',
    },
  },
  colorRed: {
    color: '#DD1A1B',
  },
  balloon: {
    marginTop: '25px',
    display: 'block',
    width: '25%',
  },
  atendente: {
    width: '70%',
    alignSelf: 'center',
    '@media (max-width:780px)': {
      display: 'none',
    },
  },
  blockOne: {
    display: 'flex',
    // justifyContent: "center",
    // alignSelf: "center",
    flexDirection: 'column !important',
    '@media (max-width:780px)': {
      padding: '10%',
    },
  },
  form: {
    '@media (max-width:780px)': {
      padding: '5%',
      paddingTop: '0px',
    },
  },
}));

const Page6: React.FC = () => {
  const classes = useStyles();
  const [objetivo, setObjetivo] = useState<any>('1');
  const [loading, setLoading] = useState<boolean>(true);
  const recaptchaRef = React.createRef();
  const [cap, setCap] = useState<any>(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur', // "onChange"
  });
  const onSubmit = async (data) => {
    if (!cap) {
      alert('Confirme o recaptcha!');
      return false;
    }
    setLoading(true);

    const dados = {
      nome: data.name,
      email: data.email,
      telefone: data.telefone,
      objetivo,
      local: 2,
      auth: '3f018bfb43591ccb2ef46f10de832160',
    };
    try {
      const response = await axios.post(
        'https://api.goey.com.br/ws/wyncorp/rest/public/wyncorplead',
        dados
      );
      if (parseInt(response.data.retorno) == 1) {
        alert('Sua mensagem foi enviada!');
        setLoading(false);
        setCap(null);
        reset({ name: '' });
        reset({ telefone: '' });
        reset({ email: '' });
      } else {
        alert('Tente novamente!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  function onChange(value) {
    if (value == null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    setCap(value);
  }

  return (
    <Container className={classes.Container}>
      <Grid container xs={12} md={12} className={classes.containerFluid}>
        <Grid item md={2} implementation="css" component={''} />
        <Grid item md={4} xs={12} className={classes.blockOne}>
          <p className={classes.title}>
            "O jeito mais fácil de{' '}
            <span className={classes.colorRed}>investir,</span> diferença para
            quem <span className={classes.colorRed}>mora</span> e para quem{' '}
            <span className={classes.colorRed}>investe.</span>"
          </p>
          <img src={balloon} className={classes.balloon} />
          {/* <img src={atendente} className={classes.atendente} /> */}
        </Grid>
        <Grid item md={1} implementation="css" component={''} />
        <Grid item md={3} xs={12} className={classes.form}>
          {/* Form */}
          <div className="App">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                  placeholder="Nome"
                  className="input"
                  {...register('name', { required: true, minLength: 10 })}
                />
                {errors.name && <p className="error">Nome obrigatório</p>}
              </div>

              <div>
                <input
                  className="input"
                  placeholder="E-mail"
                  {...register('email', { required: true })}
                />
                {errors.email && <p className="error">E-mail obrigatório</p>}
              </div>
              <div>
                <input
                  placeholder="Telefone"
                  maxLength={15}
                  className="input"
                  onKeyPress={() => {
                    setValue('telefone', phoneMask(getValues('telefone')));
                  }}
                  {...register('telefone', {
                    required: true,
                  })}
                />
                {errors.telefone && (
                  <p className="error">Telefone é obrigatório</p>
                )}
              </div>
              <p className="formP">Meu objetivo final é:</p>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ marginRight: '20px' }}>
                  <input
                    type="radio"
                    id="tipo"
                    name="tipo"
                    value="1"
                    onClick={(v) => setObjetivo(v.target.value)}
                    defaultChecked
                  />
                  <label>Moradia</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="tipo"
                    name="tipo"
                    value="2"
                    onClick={(v) => setObjetivo(v.target.value)}
                  />
                  <label>Investimento</label>
                </div>
              </div>
              <ReCAPTCHA
                sitekey="6Ldzb98ZAAAAAPELzYUDPC2L1bRcijMrLNX8MoJk"
                onChange={onChange}
                ref={recaptchaRef}
              />
              <input
                type="submit"
                value="Enviar"
                className="btn"
                disabled={loading}
              />
            </form>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Page6;
