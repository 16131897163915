import React from 'react';
// @ts-nocheck
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Processo from './pages/Processo';
import Empreendimento from './pages/Empreendimento';
import Olbia from './pages/Olbia';
import Conceicao from './pages/Conceicao';
import ScrollToTop from './components/ScrollToTop';
import Contato from './pages/Contato';

import Page8 from './components/Olbia/Page8';
import Galeria from './pages/Galeria';
import GaleriaCon from './pages/GaleriaCon';

const Rotas = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/processo" element={<Processo />} />
        <Route path="/empreendimento" element={<Empreendimento />} />
        <Route path="/olbia" element={<Olbia />} />
        <Route path="/conceicao" element={<Conceicao />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/galeria-ri" element={<Galeria />} />
        <Route path="/galeria-con" element={<GaleriaCon />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

export default Rotas;
